import styled from 'styled-components';

const FormControl = styled.div.attrs(props => ({
  marginTop: props.marginTop || '0px',
  marginBottom: props.marginBottom || '20px',
  align: props.align || 'left'
}))`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  text-align: ${props => props.align};

`
export default FormControl;

