import React from "react"
import { graphql } from 'gatsby'
import SEO from "../components/Seo/Seo"
import Layout from "../components/Layout/Layout"
import PageHeader from '../components/Layout/PageHeader/PageHeader'
import SectionPageMargin from '../components/Layout/Section/SectionPageMargin'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import FormControl from '../components/Form/FormControl'
import Select from '../components/Form/Select'
import Masonry from 'react-masonry-css'
import './review.css'
import * as Markdown from 'react-markdown'

//★★★★☆
const ReviewPage = ({ location, data }) => {

  let totalReviews = data.allContentfulReviews.edges.length;
  let sum = 0;
  for (var i = 0; i < totalReviews; i++) {
    sum += data.allContentfulReviews.edges[i].node.starRating;
    data.allContentfulReviews.edges[i].node.order = i;
  }
  let avgRating = sum / totalReviews;
  avgRating = Math.round(avgRating * 10) / 10;


  


  function sortReviews(o, key, direction) {
    let index = [];
    //let i = 0;

    for (var x in o) {
      index.push({ 'key': x, 'order': o[x].node[key], 'review': o[x].node });
    }

    index.sort(function (a, b) {
      var as = a['order'],
        bs = b['order'];

      if (direction === 'DESC') {
        return as === bs ? 0 : (as > bs ? -1 : 1);
      } else {
        return as === bs ? 0 : (as > bs ? 1 : -1);
      }

    });

    return index;
  }


  var byRating = sortReviews(data.allContentfulReviews.edges, 'order', 'ASC');

  const [optionsState, setOptionsState] = React.useState("Most Recent");
  const [reviewState, setReviewState] = React.useState(byRating);

  const handleOptionChange = changeEvent => {
    let sorted = "";
    if(changeEvent.target.value === "Highest Rating") {
      sorted = sortReviews(data.allContentfulReviews.edges, 'starRating', 'DESC');
    }
    if(changeEvent.target.value === "Lowest Rating") {
      sorted = sortReviews(data.allContentfulReviews.edges, 'starRating', 'ASC');
    }
    if(changeEvent.target.value === "Most Recent") {
      sorted = sortReviews(data.allContentfulReviews.edges, 'order', 'ASC');
    }


    //console.log(sorted);
    setReviewState(sorted);
    setOptionsState(changeEvent.target.value);
  };


  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1
  };

  

  const reviews = reviewState.map((item, i) =>

    <Card key={i}>

      <BackgroundImage
        fluid={item.review.carPhoto.fluid}
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '200px'
        }}
      ><Sold>SOLD</Sold></BackgroundImage>

      <Container>
        <NameBlock>
          <div><CustomerName>{item.review.customerName}</CustomerName></div>
          <div><Verified>Verified</Verified></div>
        </NameBlock>
        {/* <div>
          {item.review.date}
        </div> */}
        <Stars style={{ '--rating': item.review.starRating }} />

        <CarName>{item.review.carName}</CarName>
        {item.review.review &&
        <p>
          <Markdown>
            {item.review.review.review}
          </Markdown> 
        </p>}
        <div>


        </div>
      </Container>


      {item.review.summonReply &&
      <Container style={{ marginTop: '15px' }}>
        <p>
          <strong>&gt;&gt; Summon team replied:</strong><br />
          {item.review.summonReply.summonReply}
          </p>
      </Container>}

    </Card>
  );


  return (
    <Layout>

      <SEO title="Summon Customer Reviews"
        description="Read what our customers have to say about their eperience of selling a car with Summon"
        location={location}
      />

      <PageHeader
        title="Summon Customer Reviews"
        text="Read what our customers have to say about their eperience of 
                  selling a car with Summon"
      />

      <SectionPageMargin>

        <RatingBar >
          <div >
            <Stars style={{ '--rating': avgRating }} /><br />
            {avgRating} stars based on {totalReviews} reviews
          </div>
          <div>
            <FormControl marginBottom='0px' marginTop='10px'>
              <Select
                type="select"
                onChange={handleOptionChange}
                value={optionsState}
              >
                <option value="Most Recent">Most Recent</option>
                <option value="Highest Rating">Highest Rating</option>
                <option value="Lowest Rating">Lowest Rating</option>
              </Select>
            </FormControl>
          </div>
        </RatingBar>


        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {/* array of JSX items */}
          {reviews}
        </Masonry>

      </SectionPageMargin>

    </Layout>
  )

}

export default ReviewPage

export const query = graphql`
  query {
    allContentfulReviews(sort: {fields: date, order: DESC}) {
      edges {
        node {
          carName
          customerName
          date(formatString: "DD/MM/YYYY")
          starRating
          review {
            review
          }
          summonReply {
            summonReply
          }
          verified
          carPhoto {
            fluid(maxWidth: 300){
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
  `



const Card = styled.div`
  float: left;
  background-color: #ffffff;
  color: #000000;
  width: 100%; //300px;
  margin: 10px 0px 30px 0px;
  padding: 0px;
 
`;

const Container = styled.div`
  padding: 10px 20px 10px 20px;
  background: #f8f8f9;
  
  
  p {
    margin-top: 0;
    line-height: 1.7rem;
    letter-spacing: -0.05rem;
  }
`;

const CarName = styled.div`
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 6px;
`;

const CustomerName = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
`;


const NameBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const RatingBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;

  @media (max-width: 700px) {
    display: block;
	} 
`;


const Sold = styled.div`
  position: absolute;
  right: 0px;
  margin: 10px;
  padding: 2px;
  color: white;
  background: #3479ae;
  text-align: center;
  width: 70px;
  font-weight: 600;
  font-size: .9rem;
 
`;
const Verified = styled.div`
  padding: 2px;
  color: white;
  background: black;
  text-align: center;
  width: 70px;
  font-size: .8rem;
`;

const Stars = styled.div`
  --percent: calc(var(--rating) / 5 * 100%);
  
  display: inline-block;
  font-size: 1.7rem;
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;
  // fa8870 orange
  // #3479ae blue

  &::before {
    content: '★★★★★';
    letter-spacing: 1px;
    background: linear-gradient(90deg, #fa8870 var(--percent), #d6d6d6 var(--percent));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;



